import { useQuery } from "@tanstack/react-query";
import { rqKeys } from "constants/reactQueryKeys";
import Farmer_API from "services/api/farmerApiService";
import { useDataFilter } from "../filter";
import { useAOSFlavour } from "../multitenant";

export function useSearchAllFarmersWithParams({ params, isSearchActive }) {
  const fetcher = async () => {
    const { data } = await Farmer_API.searchAllFarmersWithParams(params);
    return data;
  };

  return useQuery({
    queryKey: [rqKeys.farmer.searchFarmerWithParams, ...Object.values(params)],
    queryFn: fetcher,
    enabled: isSearchActive,
  });
}

export function useGetAllContractualMembers({ params }) {
  console.log({ useGetAllContractualMembersParams: params });

  const { isValidFilter: enabled } = useDataFilter();
  const { aosFlavour } = useAOSFlavour();

  const fetcher = async ({ signal }) => {
    const response = await Farmer_API.fetchAllContractualMembers(params, { signal, aosFlavour });
    return response;
  };

  // get all contractual Farmers
  return useQuery({
    queryKey: [
      rqKeys.onboarding.fetchAllContractualMembers,
      params?.nPerPage,
      params?.page,
      params?.dateRange?.[0],
      params?.dateRange?.[1],
      params?.region,
      params?.searchText,
    ],
    queryFn: fetcher,
    enabled,
    keepPreviousData: true,
  });
}
