import { getApiUrl } from "utils";
import { axiosService } from "../fetchService";
import { apiConstants } from "constants/apiConstants";
import { aosFlavours } from "constants/flavouringConstants";

export default class Farmer_API {
  static fetchAllContractualMembers = async (params, config = {}) => {
    console.log({ config });
    const url =
      config?.aosFlavour === aosFlavours.fida
        ? getApiUrl({ endpointName: apiConstants.endpointNames.farmers, path: "/getAllContractualMembers" })
        : getApiUrl({ endpointName: apiConstants.endpointNames.farmerSeason, path: "/getAllContractualMembers" });
    const { data } = await axiosService({ url, method: "GET", params, signal: config?.signal || null });
    return data;
  };
  static searchAllFarmersWithParams = async params => {
    const url = getApiUrl({
      endpointName: apiConstants.endpointNames.farmers,
      path: `search`,
    });
    const response = await axiosService({ url, method: "GET", params });
    return { data: response.data };
  };
  static createContractualFarmer = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.farmers, path: "/createContractual" });
    return axiosService({ method: "POST", url, data: values });
  };

  static changeMemberToContractual = async (values, params) => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.farmers, path: "/changeContractual" });
    return axiosService({ method: "PATCH", url, data: values, params });
  };

  static updateFarmer = async (values, params) => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.farmerSeason, path: "/updateMember" });
    return axiosService({ method: "PATCH", url, data: values, params });
  };
  static getFarmerIdRange = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.season, path: "getFarmerIdRange" });
    const { data } = await axiosService({ url, method: "GET", params });
    return data;
  };

  static uploadImage = async (formData, onUploadProgress) => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.farmers, path: "/uploadFarmerProfileImage2" });
    return axiosService({
      method: "POST",
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  };

  static fetchContractualFarmerPassword = async params => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.agent, path: "/getAgentData" });
    const { data } = await axiosService({ url, method: "GET", params });
    return data;
  };
}
